<script context="module">
	// your script goes here
</script>

<script>
	// TODO Highlight for active or passive

	let { highlight = false, title = '', href = '', icon = 'fa-home' } = $props();

	let additionalClasses = 'text-gray-900';

	if (highlight) {
		additionalClasses = 'text-red-900';
	}
</script>

<div class="flex-1 group">
	<a
		{href}
		class="flex items-end justify-center text-center mx-auto px-4 w-full {additionalClasses} "
	>
		<span class="block px-1">
			<i class="fa-solid {icon} text-xl pt-1 mb-1 block"></i>
			<span class="block text-xs pb-1">{title}</span>
		</span>
	</a>
</div>
