<script context="module">
	import { onMount } from 'svelte';
	import NavigationButton from '$lib/components/NavigationButton.svelte';
	import { getGroupId } from '$lib/states.svelte.js';
	import { Separator } from '$lib/components/ui/separator';
	import { Badge } from '$lib/components/ui/badge';

	import '../app.css';
</script>

<script>
	let groupId = $state('for preload');

	onMount(() => {
		groupId = getGroupId();
		console.log('logged on as', groupId);
	});
</script>

<svelte:head>
	<title>CowboyApp</title>
</svelte:head>

<!-- TODO Setup logged in som gruppe ID -->

<div class="min-h-screen relative default">
	<div class="headline pt-5 pb-4">
		<h1 class="text-xl font-light mb-4 w-full text-center">The Cowboy Party App &trade;</h1>

		<div class="flex pb-2">
			{#if groupId}
				<NavigationButton title="Edit" href="/group" icon="fa-user-group" highlight="true" />
				<NavigationButton title="Games" href="/games" icon="fa-play" highlight="true" />
			{/if}
			<NavigationButton title="Groups" href="/groups" icon="fa-users" />
			<NavigationButton title="Live" href="/good_players" icon="fa-person-rays" />
			<NavigationButton title="Rank" href="/board" icon="fa-ranking-star" />
		</div>
	</div>
	<div class="border-t-2 border-black pb-1"></div>

	<div class="p-2">
		<slot />
	</div>
</div>

<style>
	@import url('//cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css');
	@import url('//fonts.googleapis.com/css2?family=Varela+Round&display=swap');
	@import url('//fonts.googleapis.com/css2?family=Rye');

	:global(html, body) {
		height: 100%;
		font-family: 'Varela Round', sans-serif;
	}

	:global(h1) {
		font-weight: bold;
		text-align: center;
		font-size: 20px;
		margin: 0 0 10px 0;
	}

	:global(h2) {
		font-weight: bold;
		text-align: center;
		font-size: 20px;
		margin: 0 0 10px 0;
	}

	.headline h1 {
		font-family: Rye, Georgia, 'Times New Roman', serif;
	}

	.headline {
		background:
			url('/img/e_header_bg.png') 50% 0 no-repeat,
			url('/img/e_footer_bg.png') 50% 100% no-repeat,
			url('/img/e_article_bg.png') 50% 0 repeat-y;
		background-color: #ece6d6;
		/* margin: 0 auto; */
		/* padding: 50px 0 50px 0; */
	}

	.default {
		background-color: #ece6d6;
	}

	:global(p a) {
		font-weight: bold;
		text-decoration: underline;
	}
</style>
